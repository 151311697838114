<template>
  <div>
    <b-modal
      id="modal-export-toddler"
      size="xl"
      :title="posyandu_name == '' ? 'Daftar Balita di semua posyandu' :  `Daftar Balita di posyandu ${posyandu_name}`"
      body-class=""
      hide-footer
    >
      <div class="row justify-content-start">
        <vue-excel-xlsx
          :data="itemsNoPagination"
          :columns="columns"
          :filename="posyandu_name == '' ? 'Balita di semua posyandu' : `Balita posyandu ${posyandu_name}`"
          :sheetname="posyandu_name == '' ? 'Balita di semua posyandu' : `Balita posyandu ${posyandu_name}`"
        >
          <b-button
            class="mr-1"
            style="background-color: #107c41"
            v-b-tooltip.hover
            title="Download Excel"
          >
            <i class="fas fa-file-excel text-white px-0"></i>
          </b-button>
        </vue-excel-xlsx>
      </div>

      <b-table
        striped
        hover
        :items="itemsNoPagination"
        :fields=" currentUser.role_key_name == 'super_admin' || currentUser.user_type_id == 3 ? fieldsSpecial: fields
        "
        class="mt-3"
        responsive
      >
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'action' ? '25%' : '' }"
          />
        </template>
        <template #cell(number)="data">
            {{data.index + 1}}
        </template>
      </b-table>
    </b-modal>
  </div>
</template>

<script>
import { getUser, setUser } from "@/core/services/jwt.service.js";
export default {
  props: {
    items: Array,
    itemsNoPagination: Array,
    posyandu: Object,
    posyandu_name: String
  },
  data() {
    return {
      currentUser: getUser(),
      fields: [
        {
          key: "number",
          label: "No",
        },
        {
          key: "registration_number",
          label: "NIK",
        },
        {
          key: "toddler_name",
          label: "Nama Balita",
        },
        {
          key: "mother_registration_number",
          label: "NIK Ibu",
        },
        {
          key: "mother_name",
          label: "Nama Ibu",
        },
        {
          field: "father_registration_number",
          label: "NIK Ibu",
        },
        {
          key: "father_name",
          label: "Nama Ayah",
        },
        {
          key: "weight",
          label: "Berat Badan Lahir (Kg)",
        },
        {
          key: "height",
          label: "Panjang Badan Lahir (Cm)",
        },
        {
          key: "head_circumference",
          label: "Lingkar Kepala (Cm)",
        },
        {
          key: "gender_display",
          label: "Jenis Kelamin",
        },
        {
          key: "order",
          label: "Anak ke -",
        },
        {
          key: "blood_type_name",
          label: "Golongan Darah",
        },
        {
          key: "is_exclusive_breastfeeding_display",
          label: "Mendapat ASI Eksklusif",
        },
        {
          key: "p2_display",
          label: "Mendapat Buku KMS",
        },
        {
          key: "description",
          label: "Keterangan",
        },
      ],
      fieldsSpecial: [
        {
          key: "number",
          label: "No",
        },
        {
          key: "posyandu_name",
          label: "Posyandu",
        },
        {
          key: "registration_number",
          label: "NIK",
        },
        {
          key: "toddler_name",
          label: "Nama Balita",
        },
        {
          key: "mother_registration_number",
          label: "NIK Ibu",
        },
        {
          key: "mother_name",
          label: "Nama Ibu",
        },
        {
          key: "father_registration_number",
          label: "NIK Ibu",
        },
        {
          key: "father_name",
          label: "Nama Ayah",
        },
        {
          key: "weight",
          label: "Berat Badan Lahir (Kg)",
        },
        {
          key: "height",
          label: "Panjang Badan Lahir (Cm)",
        },
        {
          key: "head_circumference",
          label: "Lingkar Kepala (Cm)",
        },
        {
          key: "gender_display",
          label: "Jenis Kelamin",
        },
        {
          key: "order",
          label: "Anak ke -",
        },
        {
          key: "blood_type_name",
          label: "Golongan Darah",
        },
        {
          key: "is_exclusive_breastfeeding_display",
          label: "Mendapat ASI Eksklusif",
        },
        {
          key: "p2_display",
          label: "Mendapat Buku KMS",
        },
        {
          key: "description",
          label: "Keterangan",
        },
      ],
      columns: [
        {
          field: "registration_number",
          label: "NIK",
        },
        {
          field: "toddler_name",
          label: "Nama Balita",
        },
        {
          field: "mother_registration_number",
          label: "NIK Ibu",
        },
        {
          field: "mother_name",
          label: "Nama Ibu",
        },
        {
          field: "father_registration_number",
          label: "NIK Ibu",
        },
        {
          field: "father_name",
          label: "Nama Ayah",
        },
        {
          field: "weight",
          label: "Berat Badan Lahir (Kg)",
        },
        {
          field: "height",
          label: "Panjang Badan Lahir (Cm)",
        },
        {
          field: "head_circumference",
          label: "Lingkar Kepala (Cm)",
        },
        {
          field: "gender_display",
          label: "Jenis Kelamin",
        },
        {
          field: "order",
          label: "Anak ke -",
        },
        {
          field: "blood_type_name",
          label: "Golongan Darah",
        },
        {
          field: "is_exclusive_breastfeeding_display",
          label: "Mendapat ASI Eksklusif",
        },
        {
          field: "p2_display",
          label: "Mendapat Buku KMS",
        },
        {
          field: "description",
          label: "Keterangan",
        },
      ],
      columnsSpecial: [
        {
          field: "posyandu_name",
          label: "Posyandu",
        },
        {
          field: "registration_number",
          label: "NIK",
        },
        {
          field: "toddler_name",
          label: "Nama Balita",
        },
        {
          field: "mother_registration_number",
          label: "NIK Ibu",
        },
        {
          field: "mother_name",
          label: "Nama Ibu",
        },
        {
          field: "father_registration_number",
          label: "NIK Ibu",
        },
        {
          field: "father_name",
          label: "Nama Ayah",
        },
        {
          field: "weight",
          label: "Berat Badan Lahir (Kg)",
        },
        {
          field: "height",
          label: "Panjang Badan Lahir (Cm)",
        },
        {
          field: "head_circumference",
          label: "Lingkar Kepala (Cm)",
        },
        {
          field: "gender_display",
          label: "Jenis Kelamin",
        },
        {
          field: "order",
          label: "Anak ke -",
        },
        {
          field: "blood_type_name",
          label: "Golongan Darah",
        },
        {
          field: "is_exclusive_breastfeeding_display",
          label: "Mendapat ASI Eksklusif",
        },
        {
          field: "p2_display",
          label: "Mendapat Buku KMS",
        },
        {
          field: "description",
          label: "Keterangan",
        },
      ],
    };
  },
};
</script>

<style>
</style>